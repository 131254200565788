import { authGuard } from '@/services/Authentication/authGuard';
import { createRouter, createWebHistory } from 'vue-router';
import BrokerRoutes from './broker';

import Accreditation from '@/views/broker/Accreditation/index.vue';
import ScheduleCall from '@/views/broker/ScheduleCall/index.vue';
import Welcome from '@/views/broker/Welcome/index.vue';

import Agreement from '@/views/broker/Signup/Declaration/Agreement.vue';
import CompanyAddress from '@/views/broker/Signup/ContractDetails/CompanyAddress.vue';
import Contract from '@/views/broker/Signup/ContractDetails/ContractDetails.vue';
import Email from '@/views/broker/Signup/PersonalDetails/Email.vue';
import Licensing from '@/views/broker/Signup/Licensing.vue';
import Login from '@/views/broker/Login/index.vue';
import Name from '@/views/broker/Signup/PersonalDetails/Name.vue';
import Signup from '@/views/broker/Signup/index.vue';
import Success from '@/views/broker/Signup/SuccessPage.vue';
import Verification from '@/views/broker/Signup/MobileNoVerification.vue';

import ReferralAddress from '@/views/broker/ReferralSignup/PersonalDetails/Address.vue';
import ReferralAgreement from '@/views/broker/ReferralSignup/Declaration/Agreement.vue';
import ReferralCompanyAddress from '@/views/broker/ReferralSignup/ContractDetails/CompanyAddress.vue';
import ReferralDecalaration from '@/views/broker/ReferralSignup/Declaration/Declaration.vue';
import ReferralEmail from '@/views/broker/ReferralSignup/PersonalDetails/Email.vue';
import ReferralLicensing from '@/views/broker/ReferralSignup/Licensing.vue';
import ReferralMobile from '@/views/broker/ReferralSignup/PersonalDetails/Mobile.vue';
import ReferralName from '@/views/broker/ReferralSignup/PersonalDetails/Name.vue';
import ReferralPayment from '@/views/broker/ReferralSignup/PersonalDetails/Payment.vue';
import ReferralProofOfIdentity from '@/views/broker/ReferralSignup/Documents/ProofOfIdentity.vue';
import ReferralSignature from '@/views/broker/ReferralSignup/Declaration/Signature.vue';
import ReferralSignup from '@/views/broker/ReferralSignup/index.vue';
import ReferralVerification from '@/views/broker/ReferralSignup/MobileNoVerification.vue';

const routes = [
  {
    path: '/',
    redirect: '/dashboard',
  },
  {
    path: '/login',
    component: Login,
    name: 'Login',
    beforeEnter: authGuard,
  },
  {
    path: '/referral-signup',
    component: ReferralSignup,
    name: 'ReferralSignup',
    children: [
      {
        path: '/referral-signup/name',
        name: 'ReferralName',
        component: ReferralName,
      },
      {
        path: '/referral-signup/address',
        name: 'ReferralAddress',
        component: ReferralAddress,
      },
      {
        path: '/referral-signup/email',
        name: 'ReferralEmail',
        component: ReferralEmail,
      },
      {
        path: '/referral-signup/mobile',
        name: 'ReferralMobile',
        component: ReferralMobile,
      },
      {
        path: '/referral-signup/payment',
        name: 'ReferralPayment',
        component: ReferralPayment,
      },
      {
        path: '/referral-signup/licensing',
        name: 'ReferralLicensing',
        component: ReferralLicensing,
      },
      {
        path: '/referral-signup/company-address',
        name: 'ReferralCompanyAddress',
        component: ReferralCompanyAddress,
      },
      {
        path: '/referral-signup/proof-of-identity',
        name: 'ReferralProofOfIdentity',
        component: ReferralProofOfIdentity,
      },
      {
        path: '/referral-signup/verification',
        name: 'ReferralVerification',
        component: ReferralVerification,
      },
      {
        path: '/referral-signup/declaration',
        name: 'ReferralDecalaration',
        component: ReferralDecalaration,
      },
      {
        path: '/referral-signup/agreement',
        name: 'ReferralAgreement',
        component: ReferralAgreement,
      },
      {
        path: '/referral-signup/signature',
        name: 'ReferralSignature',
        component: ReferralSignature,
      },
    ],
  },
  {
    path: '/signup',
    component: Signup,
    name: 'Signup',
    children: [
      {
        path: '/signup/contract',
        name: 'Contract',
        component: Contract,
      },
      {
        path: '/signup/name',
        name: 'Name',
        component: Name,
      },
      {
        path: '/signup/email',
        name: 'Email',
        component: Email,
      },
      {
        path: '/signup/licensing',
        name: 'Licensing',
        component: Licensing,
      },
      {
        path: '/signup/company-address',
        name: 'CompanyAddress',
        component: CompanyAddress,
      },
      {
        path: '/signup/verification',
        name: 'Verification',
        component: Verification,
      },
      {
        path: '/signup/agreement',
        name: 'Agreement',
        component: Agreement,
      },
    ],
  },
  {
    path: '/success',
    name: 'Success',
    component: Success,
  },
  {
    path: '/accreditation',
    component: Accreditation,
    name: 'Acceditation',
  },
  {
    path: '/schedule-a-call',
    component: ScheduleCall,
    name: 'ScheduleCall',
  },
  {
    path: '/welcome',
    component: Welcome,
    name: 'welcome',
  },
  ...BrokerRoutes,
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
